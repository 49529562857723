// src/App.tsx

import React, { RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
} from "react-router-dom";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { Header, Footer, FooterButton } from "./components/layout";
import { Home, CarFeePage } from "./components/pages";
import { useFullscreen } from "./components/common";
import { Keyboard,KeyboardInfo } from "@capacitor/keyboard";

import config from "./config";


const App: React.FC = () => {

  useEffect(() => {
    const preventScrollRefresh = (event : Event) => {
      event.preventDefault();
    };

    // Attach event listener to window scroll
    window.addEventListener('scroll', preventScrollRefresh, { passive: false });

    // Function to hide URL bar
    const hideURLBar = () => {
      window.scrollTo(0, 1);
  };

  // Initially hide the URL bar
  hideURLBar();

  // Hide URL bar on orientation change
  window.addEventListener("orientationchange", hideURLBar);

  // Cleanup event listener on component unmount

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", hideURLBar);
      window.removeEventListener('scroll', preventScrollRefresh);
    };
  }, []); // Empty dependency array ensures this effect runs only once





  const targetRef = useRef<HTMLElement>(null);
  const scrollView = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const fullScreenButtonRef = useRef(null);
  const bottomRef = useRef<HTMLElement>() as RefObject<HTMLDivElement>;
  const footerRef = useRef<HTMLElement>();

  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const fullScreenClickHandler = () => {
    if (isFullscreen) {
      window.removeEventListener("touchstart", enterFullscreen);
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };
  return (
    <Router>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
        />
        
        <meta name="mobile-web-app-capable" content="yes" />
      </Helmet>
      <div className="app">
        <Header title={config.applicationTitle}>
          <button
            ref={fullScreenButtonRef}
            className="fullscreen-button"
            onClick={fullScreenClickHandler}
          >
            <FontAwesomeIcon icon={faExpand} />
          </button>
        </Header>
        <main className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/car_fee/:carId" element={<CarFeePage />} />
          </Routes>
        </main>
        
        <FooterButton buttonText="🏠" onClick={() => {}} /> 
        <div ref={bottomRef}></div>
      </div>
    </Router>
  );
};

export default App;
