// useFullscreen.js
import { useCallback, useEffect, useState } from "react";

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  

  const enterFullscreen = useCallback(() => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem
        .requestFullscreen()
        .catch((err) => console.error("Fullscreen request failed:", err));
    } /*else if (elem.mozRequestFullScreen) {
      // Firefox
      elem
        .mozRequestFullScreen()
        .catch((err) => console.error("Fullscreen request failed:", err));
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem
        .webkitRequestFullscreen()
        .catch((err) => console.error("Fullscreen request failed:", err));
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem
        .msRequestFullscreen()
        .catch((err) => console.error("Fullscreen request failed:", err));
    } */
  }, []);

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .catch((err) => console.error("Exit fullscreen failed:", err));
    } /*else if (document.mozCancelFullScreen) {
      // Firefox
      document
        .mozCancelFullScreen()
        .catch((err) => console.error("Exit fullscreen failed:", err));
    } else if (document.webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      document
        .webkitExitFullscreen()
        .catch((err) => console.error("Exit fullscreen failed:", err));
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document
        .msExitFullscreen()
        .catch((err) => console.error("Exit fullscreen failed:", err));
    } */
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  return { isFullscreen, enterFullscreen, exitFullscreen };
};

export default useFullscreen;
