export interface Car {
  id: string;
  name: string;
  enName?: string;
  iconUrl: string;
}

export interface CarFee {
  carId: string;
  pickup: string;
  dropOff: string;
  fee: number;
}

export interface Location {
  id: string;
  name: string;
}

export interface SearchBoxData {
  key: string;
  value: string;
}

export const cars: Car[] = [
  {
    id: "1",
    name: "老爷车",
    enName: "Classic Car",
    iconUrl:
      "/static/images/prius.webp",
  },
  {
    id: "2",
    name: "越野车",
    enName: "SUV",
    iconUrl:
      "/static/images/rx330.webp",
  },
  {
    id: "3",
    name: "家庭车",
    enName: "Starex",
    iconUrl:
      "/static/images/starex.webp",
  },
  {
    id: "4",
    name: "阿尔法 2010",
    iconUrl:
      "/static/images/Alphard-2010.webp",
  },
  {
    id: "5",
    name: "商务车",
    iconUrl:
      "/static/images/alphard-2020.webp",
  },

];

export const carFees: CarFee[] = [
  { carId: "1", pickup: "金边", dropOff: "飞机场",  fee: 15 },
  { carId: "1", pickup: "金边", dropOff: "幸运",  fee: 25 },
  { carId: "1", pickup: "金边", dropOff: "芒果", fee: 20 },
  { carId: "1", pickup: "金边", dropOff: "南山6A", fee: 20 },
  { carId: "1", pickup: "金边", dropOff: "床公園", fee: 20 },
  { carId: "1", pickup: "金边", dropOff: "五号园区", fee: 30 },
  { carId: "1", pickup: "金边", dropOff: "新太子园区", fee: 25 },
  { carId: "1", pickup: "金边", dropOff: "西哈努克城", fee: 60 },
  { carId: "1", pickup: "金边", dropOff: "巴维特市", fee: 50 },
  { carId: "1", pickup: "金边", dropOff: "财通", fee: 35 },
  { carId: "1", pickup: "金边", dropOff: "白馬", fee: 50 },
  { carId: "1", pickup: "金边", dropOff: "贡布", fee: 50 },
  { carId: "1", pickup: "金边", dropOff: "白马省", fee: 50 },
  { carId: "1", pickup: "金边", dropOff: "波贝省", fee: 100 },
  { carId: "1", pickup: "金边", dropOff: "斯马赫", fee: 110 },
  { carId: "1", pickup: "金边", dropOff: "拜林省边界", fee: 110 },
  { carId: "1", pickup: "金边", dropOff: "苦薩", fee: 110  },
  { carId: "1", pickup: "金边", dropOff: "暹粒市", fee: 75 },
  { carId: "1", pickup: "金边", dropOff: "七星海", fee: 75 },
  { carId: "1", pickup: "金边", dropOff: "戈公省", fee: 80 },
  { carId: "1", pickup: "金边", dropOff: "马德望省", fee: 80 },
  
  { carId: "2", pickup: "金边", dropOff: "飞机场",  fee: 15 },
  { carId: "2", pickup: "金边", dropOff: "幸运",  fee: 30 },
  { carId: "2", pickup: "金边", dropOff: "芒果", fee: 25 },
  { carId: "2", pickup: "金边", dropOff: "南山6A", fee: 25 },
  { carId: "2", pickup: "金边", dropOff: "床公園", fee: 20 },
  { carId: "2", pickup: "金边", dropOff: "五号园区", fee: 35 },
  { carId: "2", pickup: "金边", dropOff: "新太子园区", fee: 30 },
  { carId: "2", pickup: "金边", dropOff: "西哈努克城", fee: 65 },
  { carId: "2", pickup: "金边", dropOff: "巴维特市", fee: 55 },
  { carId: "2", pickup: "金边", dropOff: "财通", fee: 40 },
  { carId: "2", pickup: "金边", dropOff: "白馬", fee: 55 },
  { carId: "2", pickup: "金边", dropOff: "贡布", fee: 55 },
  { carId: "2", pickup: "金边", dropOff: "白马省", fee: 55 },
  { carId: "2", pickup: "金边", dropOff: "波贝省", fee: 105 },
  { carId: "2", pickup: "金边", dropOff: "斯马赫", fee: 115 },
  { carId: "2", pickup: "金边", dropOff: "拜林省边界", fee: 115 },
  { carId: "2", pickup: "金边", dropOff: "苦薩", fee: 115  },
  { carId: "2", pickup: "金边", dropOff: "暹粒市", fee: 80 },
  { carId: "2", pickup: "金边", dropOff: "七星海", fee: 80 },
  { carId: "2", pickup: "金边", dropOff: "戈公省", fee: 85 },
  { carId: "2", pickup: "金边", dropOff: "马德望省", fee: 85 },

  { carId: "3", pickup: "金边", dropOff: "飞机场",  fee: 35 },
  { carId: "3", pickup: "金边", dropOff: "幸运",  fee: 40 },
  { carId: "3", pickup: "金边", dropOff: "芒果", fee: 35 },
  { carId: "3", pickup: "金边", dropOff: "南山6A", fee: 35 },
  { carId: "3", pickup: "金边", dropOff: "床公園", fee: 35 },
  { carId: "3", pickup: "金边", dropOff: "五号园区", fee: 45 },
  { carId: "3", pickup: "金边", dropOff: "新太子园区", fee: 40 },
  { carId: "3", pickup: "金边", dropOff: "西哈努克城", fee: 100 },
  { carId: "3", pickup: "金边", dropOff: "巴维特市", fee: 80 },
  { carId: "3", pickup: "金边", dropOff: "财通", fee: 65 },
  { carId: "3", pickup: "金边", dropOff: "白馬", fee: 80 },
  { carId: "3", pickup: "金边", dropOff: "贡布", fee: 80 },
  { carId: "3", pickup: "金边", dropOff: "白马省", fee: 80 },
  { carId: "3", pickup: "金边", dropOff: "波贝省", fee: 140 },
  { carId: "3", pickup: "金边", dropOff: "斯马赫", fee: 160 },
  { carId: "3", pickup: "金边", dropOff: "拜林省边界", fee: 160 },
  { carId: "3", pickup: "金边", dropOff: "苦薩", fee: 160 },
  { carId: "3", pickup: "金边", dropOff: "暹粒市", fee: 105 },
  { carId: "3", pickup: "金边", dropOff: "七星海", fee: 110 },
  { carId: "3", pickup: "金边", dropOff: "戈公省", fee: 160 },
  { carId: "3", pickup: "金边", dropOff: "马德望省", fee: 120 },

  { carId: "4", pickup: "金边", dropOff: "飞机场",  fee: 30 },
  { carId: "4", pickup: "金边", dropOff: "幸运",  fee: 45 },
  { carId: "4", pickup: "金边", dropOff: "芒果", fee: 40 },
  { carId: "4", pickup: "金边", dropOff: "南山6A", fee: 40 },
  { carId: "4", pickup: "金边", dropOff: "床公園", fee: 40 },
  { carId: "4", pickup: "金边", dropOff: "五号园区", fee: 50 },
  { carId: "4", pickup: "金边", dropOff: "新太子园区", fee: 45 },
  { carId: "4", pickup: "金边", dropOff: "西哈努克城", fee: 100 },
  { carId: "4", pickup: "金边", dropOff: "巴维特市", fee: 90 },
  { carId: "4", pickup: "金边", dropOff: "财通", fee: 80 },
  { carId: "4", pickup: "金边", dropOff: "白馬", fee: 95 },
  { carId: "4", pickup: "金边", dropOff: "贡布", fee: 95 },
  { carId: "4", pickup: "金边", dropOff: "白马省", fee: 95 },
  { carId: "4", pickup: "金边", dropOff: "波贝省", fee: 180 },
  { carId: "4", pickup: "金边", dropOff: "斯马赫", fee: 200 },
  { carId: "4", pickup: "金边", dropOff: "拜林省边界", fee: 200 },
  { carId: "4", pickup: "金边", dropOff: "苦薩", fee: 200  },
  { carId: "4", pickup: "金边", dropOff: "暹粒市", fee: 130 },
  { carId: "4", pickup: "金边", dropOff: "七星海", fee: 130 },
  { carId: "4", pickup: "金边", dropOff: "戈公省", fee: 160 },
  { carId: "4", pickup: "金边", dropOff: "马德望省", fee: 135 },

  { carId: "5", pickup: "金边", dropOff: "飞机场",  fee: 90 },
  { carId: "5", pickup: "金边", dropOff: "幸运",  fee: 110 },
  { carId: "5", pickup: "金边", dropOff: "芒果", fee: 90 },
  { carId: "5", pickup: "金边", dropOff: "南山6A", fee: 90 },
  { carId: "5", pickup: "金边", dropOff: "床公園", fee: 90 },
  { carId: "5", pickup: "金边", dropOff: "五号园区", fee: 110 },
  { carId: "5", pickup: "金边", dropOff: "新太子园区", fee: 110 },
  { carId: "5", pickup: "金边", dropOff: "西哈努克城", fee: 200 },
  { carId: "5", pickup: "金边", dropOff: "巴维特市", fee: 190 },
  { carId: "5", pickup: "金边", dropOff: "财通", fee: 140 },
  { carId: "5", pickup: "金边", dropOff: "白馬", fee: 200 },
  { carId: "5", pickup: "金边", dropOff: "贡布", fee: 200 },
  { carId: "5", pickup: "金边", dropOff: "白马省", fee: 200 },
  { carId: "5", pickup: "金边", dropOff: "波贝省", fee: 430 },
  { carId: "5", pickup: "金边", dropOff: "斯马赫", fee: 450 },
  { carId: "5", pickup: "金边", dropOff: "拜林省边界", fee: 450 },
  { carId: "5", pickup: "金边", dropOff: "苦薩", fee: 430  },
  { carId: "5", pickup: "金边", dropOff: "暹粒市", fee: 310 },
  { carId: "5", pickup: "金边", dropOff: "七星海", fee: 270 },
  { carId: "5", pickup: "金边", dropOff: "戈公省", fee: 310 },
  { carId: "5", pickup: "金边", dropOff: "马德望省", fee: 310 },


  
];

export const locations: Location[] = [
  { id: "1", name: "Phnom Penh" },
  { id: "2", name: "Kandal" },
  { id: "3", name: "Takeo" },
  { id: "4", name: "Kompong Speu" },
];
