import React, { useState, useRef, useEffect, ReactNode } from "react";
import "./TouchScrollContainer.css"; // Import CSS file for styling

interface TouchScrollContainerProps {
  children: ReactNode;
  panelHeight: string;
}

const TouchScrollContainer: React.FC<TouchScrollContainerProps> = ({ children, panelHeight }) => {
  const [startTouchY, setStartTouchY] = useState<number | null>(null);
  const [startScrollTop, setStartScrollTop] = useState<number | null>(null);
  const [velocity, setVelocity] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastTouchYRef = useRef<number | null>(null);
  const lastTouchTimeRef = useRef<number | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (containerRef.current) {
        containerRef.current.scrollLeft += event.deltaX;
        containerRef.current.scrollTop += event.deltaY;
        event.preventDefault(); // Prevent default browser scroll behavior
      }
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener("wheel", handleWheel, { passive: false });
      return () => {
        containerElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      setStartTouchY(e.touches[0].clientY);
      setStartScrollTop(containerRef.current.scrollTop);
      lastTouchYRef.current = e.touches[0].clientY;
      lastTouchTimeRef.current = e.timeStamp;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startTouchY !== null && startScrollTop !== null && containerRef.current) {
      const currentTouchY = e.touches[0].clientY;
      const deltaY = startTouchY - currentTouchY;
      containerRef.current.scrollTop = startScrollTop + deltaY;

      const now = e.timeStamp;
      const lastTouchY = lastTouchYRef.current;
      const lastTouchTime = lastTouchTimeRef.current;
      if (lastTouchY !== null && lastTouchTime !== null) {
        const deltaTouchY = currentTouchY - lastTouchY;
        const deltaTime = now - lastTouchTime;
        const newVelocity = -deltaTouchY / deltaTime; // Invert to ensure correct direction
        setVelocity(newVelocity);
      }
      lastTouchYRef.current = currentTouchY;
      lastTouchTimeRef.current = now;
    }
  };

  const handleTouchEnd = () => {
    const decay = 0.45;
    const minVelocity = 0.1;

    const animateScroll = () => {
      if (containerRef.current && Math.abs(velocity) > minVelocity) {
        containerRef.current.scrollTop += velocity * 16; // Assuming 60fps, 16ms per frame
        setVelocity(prevVelocity => prevVelocity * decay);
        animationFrameRef.current = requestAnimationFrame(animateScroll);
      }
    };

    setStartTouchY(null);
    setStartScrollTop(null);
    animationFrameRef.current = requestAnimationFrame(animateScroll);
  };

  return (
    <div
      className="touch-scroll-container"
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ height: panelHeight }}
    >
      {children}
    </div>
  );
};

export default TouchScrollContainer;
