// FooterButton.tsx

import React, { RefObject } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeAlt } from "@fortawesome/free-solid-svg-icons";
import "./FooterButton.css";

interface FooterButtonProps {
  buttonText: string;
  onClick: () => void;
  buttonStyle?: React.CSSProperties;
}

const FooterButton: React.FC<FooterButtonProps> = ({
  buttonText,
  onClick,
  buttonStyle,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  return (
    <div style={{position:"absolute",bottom:0}}>
    <footer className="footer-button-container">
      <div className="dock" />
      <button
        className="footer-button"
        style={buttonStyle}
        onClick={() => {
          handleButtonClick();
        }}
      >
        <FontAwesomeIcon
          icon={faHomeAlt}
          style={{ color: "gold", fontSize: "2.5rem" }}
        />
      </button>
    </footer>
    </div>
  );
};

export default FooterButton;
