// src/components/CategoryIconList.tsx

import React, { ReactElement, ReactNode } from "react";
import "./style.css"; // Import CSS for styling
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { cars, Car } from "../../../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config"

interface CarCategoryProps {
  categories: Car[];
  children: ReactElement[];
}


const CarCategory: React.FC<CarCategoryProps> = ({ categories,children }) => {
  const navigate = useNavigate();

  return (
    <div className="category-icon-list">
      {categories.map((category, index) => (
        <div
          key={index}
          className="category-item"
          onClick={() => {
            navigate("/car_fee/" + category.id);
          }}
        >
          <button>
            <img
              src={category.iconUrl}
              alt={category.name}
              className="category-icon"
            />
          </button>
          <div className="category-name-container">
            <span className="category-name">{category.name}</span>
          </div>
        </div>
      ))}
      {children}  
    </div>
  );
};

export default CarCategory;
