import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { CarCategory } from "../../common";
import { cars } from "../../../data";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip,faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";
import { Button } from "react-native";

const Home: React.FC = () => {

  const navigate = useNavigate();

  const [view,setView] = useState('main');
  const categories = cars;

  const sendTelegramMessage=(text:string):void=>{
    const message = encodeURIComponent(text);
    const telegramURL = `${config.telegramUrl}?text=${message}`;
    window.open(telegramURL, "_Blank");
  }

  const khmerLanguageClickHandler=()=>{
  const textMessage =
    "ខ្ញុំត្រូវការកក់ Taxi";
    sendTelegramMessage(textMessage) ;
  }

const otherClickHandler=()=>{
  setView('other')
}

useEffect(() => {
  const handlePopState = (event: PopStateEvent) => {
    if (window.confirm("Do you really want to leave?")) {
      // Allow the navigation
      navigate(-1);
    } else {
      // Prevent the navigation
      event.preventDefault();
      window.history.pushState(null, "", window.location.pathname);
    }
  };

  window.addEventListener('popstate', handlePopState);

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}, [navigate]);
    
  return (
    <div>
      <img
        className="banner"
        src="/static/images/banner.webp"
        alt="Image"
        title="Image"
        style={{border:0}}
      />
      {view=='main' &&
      <CarCategory categories={categories}>
        <div
          className="category-item"
          onClick={() => {
            return;
          }}
        >
          <button onClick={()=>{khmerLanguageClickHandler()}}>
            <img
              src="/static/images/prius.webp"
              className="category-icon"
            />
          </button>
          <div className="category-name-container">
            <span className="category-name">ភាសាខ្មែរ</span>
          </div>
        </div>
       <div
          className="category-item"
          onClick={() => {
            otherClickHandler();
          }}
        >
          <button style={{marginBottom:"0.3rem"}}>
            <FontAwesomeIcon icon={faGrip} color="orange" fontSize="3rem" />
          </button>
          <div className="category-name-container">
            <span className="category-name">其他</span>
          </div>
        </div>
        <div className="category-item"></div>
    </CarCategory>
     }
      {
        view=='other' && <div>
          <div className="nav_other_categories">
            <div>
              <a onClick={()=>{sendTelegramMessage("我需要订出租车")}}>
                <div className="cat_icon_bg">
                  <img src="/static/images/khmer-map.webp" alt="Custom Destination" />
                </div>
                <div className="cat_text">带你想去的地方</div>
              </a>
            </div>
            <div>
              <a onClick={()=>{sendTelegramMessage("我需要租车")}}>
                <div className="cat_icon_bg">
                  <img src="/static/images/alphard2010_86.webp" alt="Services" />
                </div>
                <div className="cat_text">汽车出租</div>
              </a>
            </div>
            <div>
              <a onClick={()=>{sendTelegramMessage("我需要租货车")}}>
                <div className="cat_icon_bg">
                  <img src="/static/images/truck.png" alt="Services" />
                </div>
                <div className="cat_text">送货卡车出租</div>
              </a>
            </div>
            <div>
              <a onClick={()=>{sendTelegramMessage("我需要送货")}}>
                <div className="cat_icon_bg">
                  <img src="/static/images/delivery.png" alt="Services" />
                </div>
                <div className="cat_text">送货服务</div>
              </a>
            </div>
            <div>
              <a onClick={()=>{sendTelegramMessage("我需要租司机")}}>
                <div className="cat_icon_bg">
                  <img src="/static/images/driver.png" alt="Services" />
                </div>
                <div className="cat_text">司机师傅</div>
              </a>
            </div>
            <div>
              <a onClick={()=>{sendTelegramMessage("我需要租公共汽车")}}>
                <div className="cat_icon_bg">
                  <img src="/static/images/bus.png" alt="Services" />
                </div>
                <div className="cat_text">公共汽车</div>
              </a>
            </div>
            <div></div>
            <div style={{textAlign:"end",alignItems:"baseline-position"}}><button onClick={()=>{setView('main')}} style={{width:"15vw",height:"15vw",borderRadius:"50%",marginTop:"18vw"}} >
            Back
            </button>
            </div>
            
            
          </div>
        </div>
        
      }
    </div>
  );
};

export default Home;
