import React, { useEffect, useState, useCallback, useRef, RefObject } from "react";
import ReactSearchBox from "react-search-box";
import { useParams, Link } from "react-router-dom";
import { TouchScrollContainer } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchLocation,
  faArrowLeft,
  faLocation,
  faRotate,
  faRoad,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";
import debounce from "lodash.debounce";

import {
  carFees,
  cars,
  locations,
  Location,
  SearchBoxData,
  CarFee,
} from "../../../data";
import "./style.css";

interface CarFeePageProps {
  carId: string;
}

interface Order {
  from: string;
  to: string;
  fee: Number;
}

const getLocationData = () => {
  const data: SearchBoxData[] = [];
  for (const location of locations) {
    data.push({ key: location.id, value: location.name });
  }
  return data;
};

const locationData = getLocationData();

const handleSendMessage = (
  car: string = "",
  location1: string = "",
  location2: string = ""
) => {
  const textMessage =
    "我需要订出租车\n"+ car + " : " + location1 + "➡" + location2;
  const message = encodeURIComponent(textMessage);

  // Construct the Telegram URL
  const telegramURL = `${config.telegramUrl}?text=${message}`;

  // Open the Telegram URL in a new tab or window

  window.open(telegramURL, "_Blank");
};


const CarFeePage: React.FC = (props) => {
  const { carId } = useParams();
  const carFeeByID = carFees.filter((carFee) => carFee.carId == carId);

  const carModel = cars.find((car) => car.id == carId)?.name;
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState<string>("");
  const [reverse,setReverse] = useState<boolean>(false);
  const [filteredFees, setFilteredFees] = useState<CarFee[]>(carFeeByID);
  const [innerHeight,setInnerHeight] = useState<number>( window.innerHeight);

  const bottomRef = useRef(null);
  const searchInputRef = useRef() as RefObject<HTMLInputElement>

    useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
      //document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
    };

    // Initial update
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (query.trim()) {
        const lowerCaseQuery = query.toLowerCase();
        setFilteredFees(
          carFeeByID.filter(
            (fee) =>
              fee.pickup.toLowerCase().includes(lowerCaseQuery) ||
              fee.dropOff.toLowerCase().includes(lowerCaseQuery)
          )
        );
      } else {
        setFilteredFees(carFeeByID);
      }
    }, 300),
    []
  );

  // Effect to trigger the debounced search whenever the query changes
  useEffect(() => {
    debouncedSearch(query);
    return debouncedSearch.cancel;
  }, [query, debouncedSearch]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleSearchButtonClick = () => {
    if (showSearch) {
      setFilteredFees(carFeeByID);
    }else{
      focusSearchInput();
    }
    setShowSearch(!showSearch);
  };
  const handleReverseButtonClick =() =>{
    setReverse (!reverse);
  }

  

  return (
    <div className="car_fee_container">
      <div className="title">
        <div id="caption">
          <h1>{carModel}</h1>
        </div>
          <div className="reverse" onClick={()=>{handleReverseButtonClick()}}>
          
          <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="2em"
      width="2em"
    >
      
      <path
        fillRule="evenodd"
        d="M1 11.5a.5.5 0 00.5.5h11.793l-3.147 3.146a.5.5 0 00.708.708l4-4a.5.5 0 000-.708l-4-4a.5.5 0 00-.708.708L13.293 11H1.5a.5.5 0 00-.5.5zm14-7a.5.5 0 01-.5.5H2.707l3.147 3.146a.5.5 0 11-.708.708l-4-4a.5.5 0 010-.708l4-4a.5.5 0 11.708.708L2.707 4H14.5a.5.5 0 01.5.5z"
        color="red"
      />
    </svg>
          
          </div>
          <div className="search" onClick={() => handleSearchButtonClick()}>
          <svg
      viewBox="0 0 22 22"
      fill="red"
      height="2em"
      width="2em"
      
    >
      <path d="M15.5 12c2.5 0 4.5 2 4.5 4.5 0 .88-.25 1.7-.69 2.4l3.08 3.1L21 23.39l-3.12-3.07c-.69.43-1.51.68-2.38.68-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5m0 2a2.5 2.5 0 00-2.5 2.5 2.5 2.5 0 002.5 2.5 2.5 2.5 0 002.5-2.5 2.5 2.5 0 00-2.5-2.5m4-12a.5.5 0 01.5.5v9.31c-.58-.55-1.25-1-2-1.31V4.7l-3 1.16V10c-.7.07-1.38.24-2 .5V5.87l-4-1.4V16.5c0 .64.09 1.26.26 1.84L8 17.9l-5.34 2.07-.16.03a.5.5 0 01-.5-.5V4.38c0-.23.15-.41.36-.48L8 2l6 2.1 5.34-2.07.16-.03M4 5.46v11.85l3-1.16V4.45L4 5.46z" />
    </svg>
          </div>
        

      </div>

      
        <div>
          {showSearch &&<div>
            <div className="search-box">
              <input type="text" className="search-input" autoFocus ref={searchInputRef} placeholder="Search Location" onChange={(e)=>{debouncedSearch(e.target.value)}} />
              <FontAwesomeIcon icon={faLocation}/>
            </div>
            
            </div>}
        </div>
      
      <div>
        
          <TouchScrollContainer panelHeight={"calc("+innerHeight+"px - 12.5rem)"}>
            {filteredFees.map((carFee, index) => (
              <div className="car_fee_card" key={index}>
                <div className="price-tag">
                  <div className="price-info">
                    <div className="source">
                      { reverse ? carFee.dropOff + '➡'+carFee.pickup : carFee.pickup + "➡" + carFee.dropOff }
                    </div>
                  </div>
                  <div className="price">{carFee.fee} $</div>
                  <div className="price">
                    <button
                      onClick={() =>
                        handleSendMessage(carModel,reverse ? carFee.dropOff : carFee.pickup ,reverse ? carFee.pickup : carFee.dropOff)
                      }
                    >
                      下单
                    </button>
                  </div>
                </div>
              </div>
            ))}{" "}
          </TouchScrollContainer>
      </div>
      <div ref={bottomRef}></div>
    </div>
  );
};

export default CarFeePage;
