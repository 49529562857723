import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { useFullscreen } from "./components/common";


import App from "./App";

const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
