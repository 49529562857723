// src/components/Header.tsx

import React from "react";
import "./Header.css";
import { KH,CN,VN,ID } from 'country-flag-icons/react/3x2'

interface HeaderProps {
  children: React.ReactNode;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title, children }) => {
  return (
    <div className="header">
      <div style={{display:"flex",flexDirection:"row"}}>
        <div style={{width:"15vh",textAlign:"left"}}>
        <KH className="flag-icon" fontSize={"6rem"} />
        <CN className="flag-icon"/> </div>
        <div className="logo">{title}</div>
        <div style={{width:"15vh",textAlign:"right"}}>
        <VN className="flag-icon"/>
        <ID className="flag-icon"/></div>
        </div>
    </div>
  );
};

export default Header;
